.dialog-content {
  @apply min600:!max-w-[600px] min600:!w-[600px] !h-auto p-2;
}

.dialog-title {
  @apply text-title text-label-l1 truncate px-4 py-3 text-left;
}

.dialog-description {
  @apply text-body text-label-l2 px-4 pb-4 text-left;
}
